import React, { useEffect, useState, useRef } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./Slider.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, FreeMode, Pagination, Keyboard } from "swiper/modules";

const TestiSlider = () => {
  const [loading, setLoading] = useState(true);
  const [slides, setSlides] = useState();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSlides(1);
    } else {
      setSlides(3);
    }

    setLoading(false);
  }, []);

  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.slice(0, maxLength) + '..."';
    }
  }

  const testimonials = [
    {
      text: `”RedBeryl™ is the jewel in the crown of luxury lifestyle services. Members can expect to be touched by elegance and panache in the most exacting standards.”`,
      author: "Suhel Seth",
    },
    {
      text: `”When I think Red Beryl, I think super fine luxury, bespoke services that connect you to your dreams, make them come true and help you realise those wildest dreams that you thought were impossible.”`,
      author: "Suvir Saran",
      role: "Chef, Author, Educator",
    },
    {
      text: `”RedBeryl™ will comply and hopefully make every wish on that bucket list come true.”`,
      author: "Kalyani Chawla",
      role: "Founder, Rezon Luxury Silverware",
    },
    {
      text: `”I am sure RedBeryl™ will ensure delightfully indulgent and immersive experiences for discerning clients with specifically curated lifestyle management services.”`,
      author: "Boney Kapoor",
    },

    {
      text: `”I've no doubt that Manoj Adlakha with his amazing experience, understanding the luxury customer in India & understanding the market, through RedBeryl™ will deliver exactly what the customer needs.”`,
      author: "Deep Kalra",
      role: "Founder, Chairman, MakeMyTrip",
    },
    {
      text: `”My friend Manoj Adlakha has introduced RedBeryl™, a new company for lifestyle services, and I feel very proud to associate with people who have an idea & make the country proud.”`,
      author: "Kapil Dev",
      role: "Indian Former Cricketer",
    },
  ];

  return (
    <div className="swiper-container">
      {loading === false && (
        <Swiper
          slidesPerView={slides}
          spaceBetween={30}
          modules={[FreeMode, Pagination, Autoplay, Keyboard]}
          autoplay={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          keyboard={{
            enabled: true,
          }}
          loop={true}
          className="mySwiper testi"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="bg-txt">
                <div style={{ height: "60%" }}>
                  <span id="font-size-text-16" className="f-7 font-italic">
                    {truncateString(testimonial.text, 180)}
                  </span>
                </div>
                <div className="testiRole">
                  <div>
                    <b
                      id="font-size-text-16"
                      className="f-7"
                    >{`- ${testimonial.author}`}</b>
                  </div>
                  <div>
                    {testimonial.role && (
                      <b
                        id="font-size-text-16"
                        style={{
                          margin: "0",
                        }}
                        className="f-7"
                      >
                        {testimonial.role}
                      </b>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default TestiSlider;
