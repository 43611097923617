const Terms = [
  {
    heading: "Introduction",
    conds: [
      {
        txt: "These Terms and Conditions ('Conditions') govern the use of RedBeryl™'s services and the membership of the RedBeryl™ Membership Club ('Membership').",
      },
      {
        txt: `RedBeryl™ Lifestyle Services Private Limited (“RedBeryl™”) is a provider of lifestyle services management services ("Services").`,
      },
      {
        txt: "By using the Services or becoming a member, you agree to be bound by these Conditions.",
      },
    ],
  },

  {
    heading: "Membership",
    conds: [
      {
        txt: "Membership is open to individuals who are residents of India, Non-Resident Indians, Indian Origin and foreign citizens and are 18 years of age or older.",
      },
      {
        txt: `To become a member, you must complete the membership application process and pay the applicable membership fee. `,
      },
      {
        txt: "Membership is subject to approval by RedBeryl™, and RedBeryl™ reserves the right to refuse or cancel membership at its sole discretion.",
      },
    ],
  },

  {
    heading: "Membership Benefits",
    conds: [
      {
        txt: "As a member, you are entitled to access the Services provided by RedBeryl™ in accordance with these Conditions. ",
      },
      {
        txt: `The specific benefits and services available to members may vary and are subject to change at RedBeryl™'s discretion. `,
      },
      {
        txt: "Additional fees may apply for certain services or benefits, and you will be notified in advance of any such fees.",
      },
    ],
  },

  {
    heading: "Tickets and Reservations",
    conds: [
      {
        txt: `RedBeryl™ may assist members in obtaining tickets for events, performances, or reservations at restaurants, hotels, and other venues ("Tickets") through its ticket agent partners.`,
      },
      {
        txt: `All Tickets obtained through RedBeryl™ are subject to the terms and conditions set by the ticket agent partner and the venue. `,
      },
      {
        txt: "RedBeryl™ acts as an intermediary between members and the ticket agent partners and shall not be responsible for the availability, pricing, or fulfilment of Tickets.",
      },
      {
        txt: "Members acknowledge that Ticket sales are final, and refunds are subject to the terms and conditions set by the ticket agent partner.",
      },
      {
        txt: `In the event of a show being cancelled directly by the artist/promoter, if it is possible to obtain a refund of the face value of the Tickets, RedBeryl™ will assist you for the same.`,
      },
      {
        txt: `RedBeryl™ or its ticket agent partner will dispatch your Tickets through delivery agents at standard rates. Please note that RedBeryl™ shall not be liable for any failure by delivery agents to deliver your Tickets.
          `,
      },
    ],
  },

  {
    heading: "Placing a Request",
    conds: [
      {
        txt: `Members may place Requests by telephone, e-mail, or through the Members' section of the Website.`,
      },
      {
        txt: `Members should always contact their primary office in the first instance to manage all Requests (including international Requests).`,
      },
      {
        txt: "RedBeryl™, acting reasonably, reserves the right to withdraw any of the Services and/or to refuse to accept any Requests at its sole discretion.",
      },
      {
        txt: " If RedBeryl™ is unable or not obliged to deal with any Request, it will inform the Member as soon as it is reasonably practical.",
      },
      {
        txt: `You undertake that all details you provide to us for the purpose of booking, ordering, or purchasing products or services are correct, that the debit, credit, and/or Payment Card you use from time to time is your own, and that you have sufficient funds to cover the cost of the product or service.`,
      },
      {
        txt: `From time to time, the procurement or provision of certain services, products, or benefits may incur a RedBeryl™ services fee or handling charge (of which you will be notified in advance, and which may vary between RedBeryl™ offices), and in such an event, you hereby authorize RedBeryl™ to debit your Payment Card with any such handling charges or, alternatively, to invoice you in respect of such fees or charges.
              `,
      },
    ],
  },

  {
    heading: "Cancellations, Returns and Refunds",
    conds: [
      {
        txt: `The Member acknowledges that the sales contract for the supply of goods and/or services made as a result of a Request is between the Member and the relevant Supplier and that RedBeryl™ is not a party to such a contract. Cancellation of contracts with Suppliers should be addressed with the Supplier directly and will be subject to the relevant Supplier's policies.`,
      },
      {
        txt: `If a Request for a specific product or service is not available, RedBeryl™ may offer you substitute products or services of a similar description and standard. You may, at your sole discretion, refuse acceptance of such substitute products and/or services and request a full refund in the event that payment has already been made to the Supplier for the unavailable product or service.`,
      },
      {
        txt: "All descriptions of any products, services, or Benefits on the Website have been approved by the relevant Supplier. RedBeryl™ shall not be liable for inaccurate or misleading descriptions.",
      },
      {
        txt: " Payment for all products and services shall be due immediately upon acceptance of the order by the relevant Supplier.",
      },
      {
        txt: `The Member further acknowledges that for goods purchased on his or her behalf by RedBeryl™ directly from a Supplier, returns and exchanges will be subject to the terms and conditions of that Supplier, and returns or exchange of goods purchased may not always be permitted. In circumstances where RedBeryl™ is asked to source a specific item for a Member, RedBeryl™ shall inform the Member of the refund and exchange policy of that Supplier in advance. RedBeryl™ shall not be liable to the Member where a Supplier does not accept the return or exchange of an item.`,
      },
      {
        txt: ` It shall be the Member's sole responsibility to retain all proof of return of goods to a Supplier. RedBeryl™ recommends returning the goods by registered delivery or by any other similar means of ascertaining the date of the return dispatch and tracking the return. 
                  `,
      },

      {
        txt: `RedBeryl™ will inform the Members when they become aware that a refund of an order has been processed by a Supplier.`,
      },
      {
        txt: `Where orders are delivered outside the jurisdiction, any applicable customs duties and sales taxes shall not be refundable through RedBeryl™. It shall be the Member's sole responsibility to recover such monies. RedBeryl™ shall have no liability for any items held by any customs or border agency. `,
      },
      {
        txt: "In the case of premium courier services, if the Member is not at the specified delivery address to receive their Order at the scheduled time, the Member may incur further charges for subsequent attempts to re-deliver the goods.",
      },
    ],
  },
  {
    heading: "Suppliers",
    conds: [
      {
        txt: `Suppliers are responsible for providing the Member with the services, products, and Benefits a Member request us to order on their behalf from time to time. RedBeryl™ shall communicate with Suppliers on behalf of the Member unless it is more appropriate for the Member to contact the Supplier directly. `,
      },
      {
        txt: `Suppliers may impose their own terms and conditions which, in every case, apply to the supply of goods and/or services by that Supplier to the Member, and such terms and conditions shall be binding upon the Member at the time of order.`,
      },
      {
        txt: `When ordering a product or service or accessing a Benefit, the Member may be required to provide their Payment Card details. If the Member requests and authorizes RedBeryl™ to use their Payment Card to pay a Supplier for products or services, they acknowledge and agree that RedBeryl™ shall have no liability or be responsible in any way whatsoever in respect of the use of the Payment Card provided that RedBeryl™ acts in accordance with the instructions issued by the Member in relation to that.`,
      },
    ],
  },
  {
    heading: "Limitation of Liability",
    conds: [
      {
        txt: `Nothing in these Conditions shall limit or exclude RedBeryl™'s liability for: (a) death or personal injury caused by its negligence, or the negligence of its employees, agents, or subcontractors; (b) fraud or fraudulent misrepresentation; or (c) breach of the terms implied by the Indian Consumer Protection Act or any other liability which cannot be limited or excluded by applicable law. `,
      },
      {
        txt: `Subject to Clause 8.1, RedBeryl™ shall not be liable to the Member, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with these Conditions.`,
      },
      {
        txt: `Subject to Clause 8.1, RedBeryl™'s total liability to the Member, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with these Conditions shall be limited to the total amount paid by the Member for the Services in the 12 months preceding the event giving rise to the liability.`,

      },
      {
        txt: `RedBeryl™ shall have no liability for any delays or failures due to circumstances beyond its reasonable control.`
      }
    ],
  },

  {
    heading: "Intellectual Property",
    conds: [
      {
        txt: ` RedBeryl™ owns all intellectual property rights in the Website and the material published on it. These works are protected by copyright laws and treaties around the world. All such rights are reserved.`,
      },
      {
        txt: `Members may print off one copy and may download extracts of any page(s) from the Website for their personal use and may draw the attention of others within their organization to material posted on the Website.`,
      },
      {
        txt: `Members must not modify the paper or digital copies of any materials they have printed off or downloaded in any way, and must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.`,
      },
      {
        txt: `RedBeryl™’s status (and that of any identified contributors) as the authors of material on the Website must always be acknowledged.`,
      },
      {
        txt: `Members must not use any part of the materials on the Website for commercial purposes without obtaining a license to do so from RedBeryl™ or their licensors.`,
      },
      {
        txt: ` If Members print off, copy, or download any part of the Website in breach of these Conditions, their right to use the Website will cease immediately, and they must, at RedBeryl™’s option, return or destroy any copies of the materials they have made.`,
      },
    ],
  },

  {
    heading: "General",
    conds: [
      {
        txt: `RedBeryl™ is committed to protecting Members’ privacy. RedBeryl™’s Privacy Policy sets out the basis on which any personal data RedBeryl™ collects from Members, or that Members provide to RedBeryl™, will be processed by RedBeryl™. By using the Website and/or providing RedBeryl™ with their personal data, Members consent to such processing and warrant that all data provided is accurate.`,
      },
      {
        txt: `These Conditions and any document expressly referred to in them constitute the whole agreement between the parties and supersede any previous arrangement, understanding, or agreement between them relating to the subject matter they cover.`,
      },
      {
        txt: ` Each of the paragraphs of these Conditions operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.`,
      },
      {
        txt: `If RedBeryl™ fails to insist that Member perform any of their obligations under these Conditions, or if RedBeryl™ does not enforce their rights against the Member, or if RedBeryl™ delays in doing so, that will not mean that they have waived their rights against the Member, and it will not mean that the Member does not have to comply with those obligations. If RedBeryl™ does waive a default by a Member, they will only do so in writing, and that will not mean that they will automatically waive any later default by the Member.`,
      },
      {
        txt: `These Conditions are governed by Indian law. This means that the Member’s use of the Website and any dispute or claim arising out of or in connection with it will be governed by Indian law. Members and RedBeryl™ both agree that the courts of India will have non-exclusive jurisdiction. However, if a Member is a resident of a different state within India, they may also bring proceedings in the courts of their respective state.`,
      },
      {
        txt: `If Members have any complaints about the Website or any of the Services provided by RedBeryl™, please contact at support@redberyl.co.`,
      },
      {
        txt: `Any rights not expressly granted in these Conditions are reserved by RedBeryl™.`,
      },
      {
        txt: `For all welcome kits T & C applies`,
      },
    ],
  },

  {
    heading: "General",
    conds: [
      {
        txt: `Please read these Terms and Conditions carefully before using RedBeryl™'s services or becoming a member of the RedBeryl™ Membership Club. By using the services or becoming a member, Members agree to be bound by these Terms and Conditions.
                `,
      },
    ],
  },
];

export default Terms;
