import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./FAQ.scss";
import faqs from "../../Mock/FAQ";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";

const FAQ = () => {
  return (
    <>
      <Header />
      <section className="faq">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">FAQs</p>
        </div>
        <div id="header-img-wrapper" className="d-flex">
          <LazyLoadImage
            effect="blur"
            // src="https://redberyl.co/images%20only/faq-img.webp"
            src="https://redberyl.co/images%20only/FAQ-Header-images.jpg"
            alt="faq-img"
            className="faq-img header-img"
          />
          <section>
            <h1 id="font-size-heading-32" className="font-f37">
              FAQs
            </h1>

            {faqs.map((f) => (
              <>
                <div className="qna">
                  <div className="col">
                    <div className="question">
                      <span className="font-f37 f-10">Q.</span>
                      <p>{f.question}</p>
                    </div>
                    <div className="row ans">
                      <p className="font-f37 f-10">A.</p>
                      <p className="f-8" dangerouslySetInnerHTML={{ __html: f.answer }}></p>
                    </div>
                  </div>
                </div>
                <br />
              </>
            ))}
          </section>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FAQ;
