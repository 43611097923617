import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./SCOC.scss";
import { Helmet } from "react-helmet";

const SCOC = () => {
  return (
    <>
    <Header />
      <section className="SCOC">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Supplier Code of Conduct</p>
        </div>

        <h3>
          Supplier Code of Conduct for RedBeryl<sup>TM</sup> Lifestyle Services
          Private Limited:
        </h3>

        <p className="f-8">
          RedBeryl<sup>TM</sup> welcomes all quality vendors and service
          providers into our supplier network to enhance the experience of our
          customers. In order to work with RedBeryl<sup>TM</sup>'s clients and
          staff, we ask that you review and abide by the following Supplier Code
          of Conduct:
        </p>

        <p>
          <b>Liability:</b> It is expressly agreed that RedBeryl<sup>TM</sup>{" "}
          shall have no liability whatsoever in respect of the provision of
          goods or services. You agree to indemnify RedBeryl<sup>TM</sup>{" "}
          against any claims made by any person or entity against RedBeryl
          <sup>TM</sup> in respect of any loss or damage caused directly or
          indirectly by the provision of any goods or services.
        </p>

        <p>
          <b>Professionalism:</b> All goods and services for RedBeryl
          <sup>TM</sup>
          customers and staff will be performed in a professional and
          workmanlike manner and in accordance with the instructions provided at
          the time of engagement. If you are unable to provide the services in
          the timeframe or manner requested, you will inform RedBeryl
          <sup>TM</sup> as soon as possible, and no later than two (2) business
          days after services are requested if no timeframe is provided upon
          engagement.
        </p>

        <p>
          <b>Terms & Conditions:</b> Any Terms & Conditions of engagement will
          be provided to RedBeryl<sup>TM</sup> prior to the commencement of
          services, or they shall have no effect. Specifically, any
          cancellation, refund, or scheduling terms must be clearly stated at
          the outset of the engagement
        </p>

        <p>
          <b>Insurance:</b> You agree to maintain current General Liability
          insurance in an amount no less than ₹1,00,00,000 for any activities
          involving potential risks, including but not limited to transportation
          services, adventure activities, water-based excursions or charters,
          aviation charters, or activities involving firearms of any kind
        </p>

        <p>
          <b>Non-Solicitation:</b> As a supplier of RedBeryl<sup>TM</sup>, you
          and/or your group of companies and/or affiliates of any nature shall
          not, in any manner, solicit and/or accept any business from sources
          that have been made available to them by and through RedBeryl
          <sup>TM</sup>, including but not limited to RedBeryl<sup>TM</sup>'s
          customers, partner brands, or other RedBeryl<sup>TM</sup>
          suppliers. You likewise shall not in any manner access, contact,
          solicit, and/or conduct any transaction with said sources, other than
          exclusively for the provision of the services under this agreement
          without RedBeryl<sup>TM</sup>'s prior written consent
        </p>

        <p>
          <b>Non-Disparagement:</b> You and your employees further agree to
          strict non-disparagement of RedBeryl<sup>TM</sup>, our customers, our
          staff, or our partner brands, whether written or oral.
        </p>

        <p>
          <b>Data Protection and Privacy:</b> As a RedBeryl<sup>TM</sup>{" "}
          supplier, you warrant that you shall comply with any applicable data
          protection, privacy, or similar laws, including but not limited to the
          Personal Data Protection Bill (India) and any other relevant
          regulations that apply in relation to any personal data in connection
          with this Agreement (hereafter, "Protected Data"). In particular, you
          shall obtain all necessary consents to the processing of personal data
          as required for the purposes of this Agreement, including without
          limitation the disclosure of such Protected Data to RedBeryl
          <sup>TM</sup> or any third parties with whom you may share personal
          data for the purposes of providing goods and/or services. You further
          agree that you will not retain nor sell Protected Data and that you
          will process and secure Protected Data with a high standard of care.
          Each party shall indemnify the other and keep the other indemnified
          against all claims, demands, actions, costs, expenses (including but
          not limited to legal costs and disbursements on a full indemnity
          basis), losses, and damages arising from or incurred by reason of any
          wrongful processing of any Protected Data
        </p>

        <p>
          <b>Cardholder Data Security:</b> You are responsible for the security
          of any cardholder data (credit or debit) that RedBeryl<sup>TM</sup>{" "}
          provides to you by any means (orally, electronically, offline, or via
          paper-based records). In accordance with PCI guidelines, RedBeryl
          <sup>TM</sup> will monitor your compliance through its internal team.
          RedBeryl<sup>TM</sup> reserves the right to terminate any agreement
          for services with immediate effect should your security procedures not
          meet acceptable standards. By agreeing to work with RedBeryl
          <sup>TM</sup>, you acknowledge and accept the terms and conditions
          outlined in this Supplier Code of Conduct. Failure to comply with
          these terms may result in termination of the agreement and any ongoing
          business relationship.
        </p>

        <p>
          Please review and adhere to this Code of Conduct in all dealings with
          RedBeryl<sup>TM</sup>, its customers, and staff.
        </p>

        <h3>
          <i>Thank you for your cooperation and partnership</i>
        </h3>
      </section>
      <Footer />
    </>
  );
};

export default SCOC;
