export const dynamicTexts = {
  "df91a3c70389": (
      <div>
              <p className="center">Details of you membership :</p>
              <p>
                One time Joining Fee - Rs. 500,000/-
              </p>
              <p>
                Annual Recurring Fee - Rs. 190,000/-
              </p>
              <br />
              <p>
              Please fill the form below and proceed to pay the amount of Rs. 6,90,000 plus 18% GST.
              </p>
              <p>
              <b>Total Payable: Rs. 8,14,200 </b>
              </p>
            </div>
  ),
  "63ebaff70b06": (
    <div>
              <p className="center">Details of you membership :</p>
              <p>
              One-time Joining Fee: Rs 1,50,000

              </p>
              <p>
              Recurring Annual Fee: Rs 90,0000 

              </p>
              <br />
              <p>
              Please fill the form below and proceed to pay the amount of Rs. 2,40,000 plus 18% GST.
              </p>
              <p>
              <b>Total Payable: Rs. 2,83,200</b>
              </p>
            </div>
  ),
};