import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./Slider.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import { FreeMode, Pagination, Keyboard, Mousewheel } from "swiper/modules";
import SingleSlider from "./SingleSlider";

const Slider = () => {
  const [loading, setLoading] = useState(true);

  const [slides, setSlides] = useState();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSlides(1.2);
      setLoading(false);
    } else {
      setSlides(2);
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading === false ? (
        <>
          <Swiper
            slidesPerView={slides}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            keyboard={{
              enabled: true,
            }}
            mousewheel={true}
            modules={[FreeMode, Pagination, Keyboard, Mousewheel]}
            className="mySwiper membership-width"
          >
            <SwiperSlide>
              <SingleSlider
                p1=" RedBeryl stands as the grandest ever invitation-only membership
            for you and your loved ones, meticulously curated, offering
            unparalleled privileges in the world of luxury. The epitome of
            exclusivity and opulence, each member is matched with their personal
            lifestyle manager, providing tailored services with the utmost
            attention to detail."
                p2="RedBeryl™ unlocks seamless airport meet and assists, curated
            travel, exclusive invitations to events, and ultra-private clubs,
            offering a realm of privileges unrivalled in the world."
                tagline="THE EPITOME OF EXCLUSIVITY"
                heading="RedBeryl™"
                button="false"
                img="https://redberyl.co/images%20only/Capture.webp"
              />
            </SwiperSlide>

            <SwiperSlide>
              <SingleSlider
                p1="RedBeryl™ Black welcomes you to an elite community. Enjoy exclusive hotel elite status, luxurious stays, top-tier dining at 100+ exquisite restaurants and access to health and wellness clubs. Our concierge team caters to your every need anywhere, anytime. Enjoy access to retail and art, invitations to prestigious events and much more."
                p2="RedBeryl™ Black epitomizes sophistication, style and elan turning everything you have your heart on into splendid realities. Step into to a realm of extraordinary privileges."
                tagline="WHERE PASSION MEETS PERFECTION"
                heading="RedBeryl™ Black"
                button="true"
                img="https://redberyl.co/images%20only/bkack.webp"
                padding="1rem"
              />
            </SwiperSlide>
          </Swiper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Slider;
