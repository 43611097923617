const faqs = [
  {
    question: "If I join today, when can I start using my Membership?",
    answer:
      "Your RedBeryl™ Membership starts the day your application is approved, granting you immediate access to redeem benefits and embark on your luxury journey.",
  },
  {
    question: "Is RedBeryl™ a credit card?",
    answer:
      "RedBeryl™ Membership is not a credit card. It is a privileged status that grants you access to a portfolio of exclusive benefits and experiences in the realms of luxury and lifestyle.",
  },
  {
    question: "How does redeeming benefits work?",
    answer:
      "Each benefit offered to RedBeryl™  Members has a dedicated landing page, providing instructions on how to access and enjoy the specific offer. Benefits are not automatically applied, so it's necessary to redeem them to fully leverage the privileges.",
  },
  {
    question: "Is membership available nationally?",
    answer:
      "RedBeryl™ Membership is available across the country, allowing members to revel in luxury experiences wherever they are. While some benefits may have regional restrictions, our commitment is to curate offerings that can be enjoyed by our national membership base.",
  },
  {
    question: "Why can't I see the full list of benefits?",
    answer:
      "In adherence to our commitment to exclusivity, we provide access to the comprehensive list of our exclusively curated benefits exclusively to those who apply to join the RedBeryl™ community. Fill out the <a href='/contact-us' class=faqLink >Inquiry form</a> in Contact Us section to have our Relationship Manager call you and take you through this.",
  },
];

export default faqs;
