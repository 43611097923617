import React, { useState } from "react";
import "./Membership.scss";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import PdfRender from "./PdfRender";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AddMembership } from "../../api/instance";

const Membership = () => {
  const navigate = useNavigate();
  const [validEmail, setValidEmail] = useState();
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLname] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [hearFrom, setHfrom] = useState(false);
  const [message, setMesage] = useState(false);
  const [checkbox, setCheckBx] = useState(false);
  const [number, setNumber] = useState();

  const handleAddMembership = async () => {
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;
    const hearFrom = document.getElementById("hearFrom").value;
    const message = document.getElementById("message").value;
    const checkbox = document.getElementById("confirmCheckbox");

    const isEmailValid = isValidEmail(email);
    const isFirstNameValid = !!firstName;
    const isLastNameValid = !!lastName;
    const isPhoneValid = phone.length === 10;
    const isHearFromValid = !!hearFrom;
    const isMessageValid = !!message;
    const isCheckboxValid = checkbox && checkbox.checked;

    if (
      !isEmailValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isPhoneValid ||
      !isHearFromValid ||
      !isMessageValid ||
      !isCheckboxValid
    ) {
      setValidEmail(isEmailValid ? "" : "Email is Invalid");
      setFirstName(!isFirstNameValid);
      setLname(!isLastNameValid);
      setEmail(email ? "" : "Email ID is required");
      setPhone(!isPhoneValid);
      setHfrom(!isHearFromValid);
      setMesage(!isMessageValid);
      setCheckBx(!isCheckboxValid);
      return;
    }


    await AddMembership({
      firstName,
      lastName,
      email,
      phone,
      hearFrom,
      message,
    });
    navigate("/thankyou");
  };

  const handleFirstNameChange = () => {
    setFirstName(false);
  };

  const handleLastNameChange = () => {
    setLname(false);
  };

  const handleEmailChange = () => {
    setValidEmail("");
  };

  const handleMessageChange = () => {
    setMesage(false);
  };

  const handleCheckboxChange = () => {
    setCheckBx(false);
  };

  function isValidEmail(email) {
    // console.log(email);
    return /\S+@\S+\.\S+/.test(email);
  }
  const handlePhoneNumberChange = (event) => {
    setPhone(false);
    const inputPhoneNumber = event.target.value;
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, "").slice(0, 10); // Truncate to 10 digits
    setNumber(numericPhoneNumber);
  };
  return (
    <>
      <Header />
      <section className="membership">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Membership</p>
        </div>
        <div id="header-img-wrapper" className="d-flex">
          <LazyLoadImage
            className="header-img"
            effect="blur"
            src="https://redberyl.co/images%20only/Membership-Header-images.jpg"
            alt="img1"
          />
          <section>
            <h1 id="font-size-heading-32" className="header-title">
              Elevate Your Lifestyle with <br />
              RedBeryl<sup>TM</sup> Black Membership
            </h1>
            <br />

            <p id="font-size-text-16">
              Experience a life of extraordinary luxury and privilege as you
              unlock the full potential of our exclusive services where we craft
              the most extraordinary moments for you.
            </p>
            <br />

            <p id="font-size-text-16">
              From curated travel and stays at 15,000+ luxury hotels, front-row
              seats to sporting events, access to premium members-only clubs,
              luxury transportation, to 24/7 concierge services, we are
              committed to elevate your experiences anywhere across the globe.
            </p>
            <br />

            <p id="font-size-text-16">
              Your dedicated Relationship Manager is here to make
              recommendations with your preferences at the core, ensuring you
              feel truly special and indulged.
            </p>
            <br />

            <p id="font-size-text-16">
              RedBeryl Black Membership is your passport to the most elite
              lifestyle, designed for discerning individuals such as yourself.
            </p>
          </section>
        </div>
        <div className="cta" style={{ width: "100%" }}>
          <PdfRender />
          <a href="/Services">
            <button className="btn-community">Explore Services</button>
          </a>
        </div>
        <hr className="c-redhr" />

        <div className="form-wrapper">
          <div className="form">
            <h1 className="c-red">Membership Form</h1>
            <br />
            <div>
              <div className="form-group w-12">
                <label>Salutations</label>
                <select id="salutation">
                  <option disabled selected>Please Select</option>
                  <option value="Mr">Mr.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Dr">Dr.</option>
                  <option value="Prof">Prof.</option>
                </select>
              </div>

              <div className="form-group w-12 row">
                <div className="form-group w-6">
                  <label>
                    First Name<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input type="text" required id="firstName" onChange={handleFirstNameChange} />
                  {firstName ? (
                    <>
                      <span>Please Fill in the First Name</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
                <div className="form-group w-6">
                  <label>
                    Last Name<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input type="text" required id="lastName" onChange={handleLastNameChange} />
                  {lastName ? (
                    <>
                      <span>Please Fill in the Last Name</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>
                    Email Address<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input type="email" required id="email" onChange={handleEmailChange}/>
                  {validEmail && <span>{validEmail}</span>}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>
                    Phone Number<font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <input
                    type="text" // Change type to "text"
                    required
                    id="phone"
                    value={number}
                    onChange={handlePhoneNumberChange} // Add onChange event handler
                    maxLength={10}
                  />
                  {phone  ? (
                    <>
                      <span>Phone Number is Invalid</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group ">
                  <label>How did you hear about us?</label>
                  <select required id="hearFrom">
                    <option disabled>Please Select</option>
                    <option value="Word Of Mouth">Word Of Mouth</option>
                    <option value="Member Reference">Member Reference</option>
                    <option value="Online Search">Online Search</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Email/Newsletters">Email/Newsletters</option>
                    <option value="Website/Blogs">Website/Blogs</option>
                    <option value="Industry Publications">
                      Industry Publications
                    </option>
                    <option value="Others">Others </option>
                  </select>
                </div>
              </div>

                <div className="form-group w-12">
                  <label>
                    Let us know how we can help you...
                    <font style={{ color: "#93141D" }}>*</font>
                  </label>
                  <textarea type="text" id="message" required onChange={handleMessageChange} />
                  {message  ? (
                    <>
                      <span>Message is required.</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="form-group w-12">
                <div className="form-group">
                  <div className="checkClass">
                    <input
                      type="checkbox"
                      required
                      style={{ backgroundColor: "#93141D" }}
                      id="confirmCheckbox"
                      onChange={handleCheckboxChange}
                    />
                    <label className="bText">
                      {" "}
                      I confirm that I have read and agree with the
                      <b
                        style={{
                          color: "#93141D",
                          marginLeft: "4px",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          window.location.href = "/Terms-and-conditions";
                        }}
                      >
                        Terms & Conditions and Privacy Policy*
                      </b>
                    </label>
                  </div>
                  {checkbox ? (
                    <>
                      <span>CheckBox is Required</span>
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>

              <div className="membershipSubmit">
                <span>*Indicates mandatory fields</span>

                <button className="btn-community"
                  onClick={handleAddMembership}
                  type="submit"
                  value="Submit"
                  style={{ color: "#000" }}
                >Submit</button>
              </div>
            </div>
          </div>
      </section>
      <Footer />
    </>
  );
};

export default Membership;
