import React from "react";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";
import "./Refunds.scss";
const Refunds = () => {
  return (
    <>
      <Header />
      <section className="refunds">
        <div className="accord">
          <a href="/" style={{ textDecoration: "none" }}>
            <p className="f-8">Home</p>
          </a>
          <span className="f-8">{">"}</span>
          <p className="f-8 clr-f">Refunds Policy</p>
        </div>
        <p>
          At RedBeryl<sup>TM</sup>, we strive to provide our customers with the
          best possible experience. If you are not completely satisfied with our
          services, we offer a refund policy to ensure your satisfaction. Please
          review the following guidelines regarding refunds
        </p>
        <h3>Eligibility for Refunds:</h3>
        <p>
          Refunds may be available for certain exceptional circumstances, such
          as:
        </p>
        <p>
          <b>a. Service Non-Delivery:</b> If the service you have booked with
          RedBeryl™ is not provided as agreed upon or not delivered at all
          (Please note that RedBeryl<sup>TM</sup> shall not be liable for any
          failure by delivery agents to deliver your Tickets).
        </p>
        <p>
          <b>b. Service Quality: </b>If the service provided by RedBeryl
          <sup>TM</sup> does not meet the specified standards at the time of
          booking or is significantly below the agreed-upon terms.
        </p>
        <p>
          <b>c. Cancellation:</b> If you cancel a service booking within the
          specified cancellation period and are eligible for a refund according
          to the Refund Process.{" "}
        </p>
        <hr className="c-red" />
        <h3>To request a refund, please follow these steps:</h3>
        <p>
          <b>a. Contact Customer Support:</b> Reach out to our customer support
          team via email or phone, providing details of your refund request,
          including the reason for the refund and any supporting evidence or
          documentation.
        </p>
        <p>
          <b>b. Review and Evaluation: </b>Our team will review your refund
          request and evaluate it based on the eligibility criteria mentioned
          above.
        </p>
        <p>
          <b>c. Refund Decision:</b> Upon completion of the evaluation, we will
          notify you of the refund decision. If your refund request is approved,
          we will initiate the refund process.
        </p>

        <h3>Refund Methods: </h3>

        <p>
          The refund will be processed using the same payment method used for
          the original transaction unless otherwise agreed upon. The timeline
          for the refund to be reflected in your account may vary depending on
          the payment provider and your bank's processing time
        </p>

        <hr className="c-red" />

        <h3>Non-Refundable Items / Services: </h3>

        <p>
          Certain items or services may be non-refundable as per our terms and
          conditions. These may include but are not limited to third-party fees,
          taxes, or charges incurred during the provision of services. Please
          note that this refund policy is subject to the specific terms and
          conditions applicable to each booking or service engagement. We
          encourage you to review the terms and conditions provided at the time
          of booking or contact our customer support for further assistance.
          <br />
          At RedBeryl<sup>TM</sup>, we are committed to resolving any issues or
          concerns you may have regarding our services. Your satisfaction is our
          priority, and we will do our best to address any refund requests in a
          fair and timely manner
        </p>

        <p>
          <b>
            For more information or assistance regarding refunds, please contact
            our customer support team at{" "}
            <span className="f-red">support@redberyl.co</span>
          </b>
        </p>
      </section>
      <Footer />
    </>
  );
};

export default Refunds;
