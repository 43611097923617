import React from "react";
import "./Home.scss";
import Slider from "./components/Slider";
import SliderCountry from "./components/SliderCountry";
import ImageSlider from "./components/ImageSlider";
import TestiSlider from "./components/TestiSlider";
import { testimonial_Video } from "../../Constant/Constant";
import ReactPlayer from "react-player";
const Home = () => {
  return (
    <>
      <main className="hero">
        <div
          style={{
            height: "60vh",
          }}
          className="hero-section"
        >
          <p className="c-white">
            Embark on Unrivalled Luxury With Privileges Like Never Before
          </p>
        </div>
        <div className="info f-8">
          <p>
            Welcome to RedBeryl
            <span className="tmFont">
              <span className="tmFont">™</span>
            </span>
            , the world's first membership that redefines luxury with tangible
            advantages that anticipates your expectations.
          </p>

          <p>
            As your ultimate lifestyle services manager and dedicated personal
            office extension, we deliver boundless indulgence.
          </p>

          <p>
            Experience the extraordinary, where access becomes a reality, and
            dreams turn into cherished memories at RedBeryl
            <span className="tmFont">™</span>.{" "}
          </p>

          <hr className="c-red" />

          <h1 className="font-f37">Membership</h1>

          <span >
            Discover RedBeryl<span className="tmFont">™</span> where perfection
            and passion converge. Join the legendary membership for a
            magnificient experience.
          </span>

          <Slider />

          <hr className="c-red" />
          <h1 className="font-f37">
            Elevate Your Journey.
            <br />
            Exclusive for You.
          </h1>

          <span>
            Discover extraordinary experiences as a RedBeryl
            <span className="tmFont">™</span> member: dine with kings, witness
            the Southern Lights on an iceberg, enjoy private opera screenings,
            explore the Pyramids of Giza after hours, and more. We turn the
            impossible into reality, understanding and fulfilling your needs
            worldwide. Your desires, our priority. RedBeryl
            <span className="tmFont">™</span> - Crafting extraordinary
            experiences for you.
          </span>

          <ImageSlider />

          <div className="alert">
            <h1 className="font-f37">
              The RedBeryl
              <span className="tmFont">
                <span className="tmFont">™</span>
              </span>{" "}
              List
            </h1>
            <br />
            <p id="font-size-text-16" className="font-italic">
              Unleash your passions and desires in the extraordinary realm of
              the RedBeryl<span className="tmFont">™</span> List.
            </p>
            <p id="font-size-text-16" className="font-italic">
              Witness awe-inspiring wonders, experience iconic events, and
              fulfil lifelong dreams.
            </p>

            <p id="font-size-text-16" className="font-italic">
              Embark on a journey where every moment is a dream come true backed
              by our commitment to excellence.
            </p>
          </div>

          <h1 className="font-f37">
            Explore the RedBeryl
            <span className="tmFont">
              <span className="tmFont">™ </span>
            </span>
            Experiences
          </h1>

          <span>
            At RedBeryl<span className="tmFont">™</span>, our impeccable service
            pillars are designed to enhance your moments with exquisite
            experiences and benefits.
          </span>

          <SliderCountry />
          <div className="learnMoreBtn">
            <a href="/Services">
              <button className="btn-community">Learn More</button>
            </a>
          </div>
          <hr className="c-red" />
          <h2 className="font-f37 font-36">
            Where dreams converge, bespoke services unfold.
          </h2>
          <hr className="c-red" />

          <h1 className="font-f37">Messages from our supporters...</h1>

          <span>
            Our well-wishers praise us for crafting extraordinary moments and
            delivering a world of personalized service that is par excellence.
          </span>
          <div className="video-slider-wrapper">
            <div
              style={{
                position: "relative",
              }}
              className="video-container"
            >
              <div className="responsive-video">
                <ReactPlayer
                  playing={false}
                  controls={true}
                  volume={40}
                  muted={true}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  url={testimonial_Video}
                />
              </div>
            </div>
          </div>
          <div className="slider-container">
            <TestiSlider />
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
