import React from "react";
import "./Error.scss";
import Header from "../../shared/Header";
import Footer from "../../shared/Footer";

const Error = () => {
  return (
    <>
      <Header />
        <section className="SCOC">
          <div className="accord">
            <a href="/" style={{ textDecoration: "none" }}>
              <p className="f-8">Home</p>
            </a>
            <span className="f-8">{">"}</span>
            <p className="f-8 clr-f">Error</p>
          </div>
          <h3>Sorry the page you looking for is not available</h3>

          <a href="/" className="errorBtn">
            <button>Go Home</button>
          </a>
        </section>
      <Footer />
    </>
  );
};

export default Error;
