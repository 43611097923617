import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./Slider.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard, Mousewheel } from "swiper/modules";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageSlider = () => {
  let [count, setCount] = useState(2);

  const [loading, setLoading] = useState(true);
  const [screenWidth, setScreenWidth] = useState(true);

  useEffect(() => {
    if (screenWidth >= 1400) {
      setCount(3);
      setLoading(false);
    } else if (screenWidth > 768) {
      setCount(2);
      setLoading(false);
    } else if (screenWidth > 480) {
      setCount(2);
      setLoading(false);
    } else {
      setCount(1);
      setLoading(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    let width = window.innerWidth;
    setScreenWidth(width);
  }, []);

  return (
    <>
      {loading === false ? (
        <>
          <Swiper
            slidesPerView={count}
            spaceBetween={screenWidth > 1440 ? 45 : screenWidth < 440 ? 15 : 30}
            modules={[Pagination, Autoplay, Keyboard, Mousewheel]}
            loop={true}
            lazyPreloadPrevNext={2}
            mousewheel={true}
            autoplay={{
              delay: 3000, // Increase the delay for smoother transitions
              disableOnInteraction: true, // Pause autoplay on interaction
            }}
            keyboard={{
              enabled: true,
            }}
            pagination={{
              dynamicBullets: true,
              clickable:true
            }}
            className="mySwiper imgs"
          >
            <SwiperSlide>
              <div className="bg-img">
                <LazyLoadImage
                  effect="blur"
                  src="https://redberyl.co/images%20only/exp1.webp"
                  alt="txt"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="bg-img">
                <LazyLoadImage
                  effect="blur"
                  src="https://redberyl.co/images%20only/exp2.webp"
                  alt="txt"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="bg-img">
                <LazyLoadImage
                  effect="blur"
                  src="https://redberyl.co/images%20only/exp3.webp"
                  alt="txt"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="bg-img">
                <LazyLoadImage
                  effect="blur"
                  src="https://redberyl.co/images%20only/exp4.webp"
                  alt="txt"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ImageSlider;
